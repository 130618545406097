/**
 * Application.js only declares the assets as offered by @lendahand/frontend
 *
 * See https://github.com/lendahand/frontend
 *
 */

import "application.js";
import "application.css";
import "./backoffice/country_select";

require.context("../images", true);